$(document).ready(function () {
    var Storage = window.localStorage;
    var sessionStorage = window.sessionStorage;
    var now = Date.now();

    var modal = $('#pageModal1');
    var modal2 = $('#pageModal2');
    if (typeof (sessionStorage) !== "undefined") {
        var modalToShow = modal;
        var isModal1Showed = sessionStorage.getItem("showModal1");
        if (isModal1Showed !== null) {
            if (isModal1Showed == 1) {
                sessionStorage.setItem("showModal1", 0);
                modalToShow = modal2;
            }else{
                sessionStorage.setItem("showModal1", 1);
            }
        } else {
            sessionStorage.setItem("showModal1", 1);
        }
        setupModal(modalToShow)
    }

    function setupModal(modal) {
        var closeBtn = modal.find('.close');
        var secondsToShow = parseInt(modal.data('seconds')) * 1000;
        var frequently = modal.data('frequently');
        var popupId = modal.data('popupid');
        modal.find('a').on('click', function(e){
            updateLinksClick(popupId);
        });
        if (frequently === 'Everytime') {
            if (secondsToShow > 0) {
                setTimeout(function () {
                    modal.show();
                    updateCount(popupId);
                }, secondsToShow);
            } else {
                modal.show();
                updateCount(popupId);
            }
        } else if (frequently === 'Once') {
            if (typeof (Storage) !== "undefined") {
                var popupName = 'popup_' + popupId;
                var popup = Storage.getItem(popupName);
                if (popup === null) {
                    var popupData = {
                        id: popupId,
                        time: now,
                    };
                    Storage.setItem(popupName, JSON.stringify(popupData));
                    if (secondsToShow > 0) {
                        setTimeout(function () {
                            modal.show();
                            updateCount(popupId);
                        }, secondsToShow);
                    } else {
                        modal.show();
                        updateCount(popupId);
                    }
                }
            }
        } else if (frequently === '24H') {
            if (typeof (Storage) !== "undefined") {
                var popupName = 'popup_' + popupId;
                var popup = Storage.getItem(popupName);
                if (popup === null) {
                    var popupData = {
                        id: popupId,
                        time: now,
                    };
                    popup = JSON.stringify(popupData)
                    Storage.setItem(popupName, popup);
                } else {
                    var _24hToMiliSeconds = 24 * 60 * 60 * 1000;
                    popupData = JSON.parse(popup);
                    var popupShowedId = popupData.id;
                    var popupShowedAt = popupData.time;
                    if (popupId === popupShowedId) {
                        var timeCount = now - popupShowedAt;
                        if (timeCount > _24hToMiliSeconds) {
                            if (secondsToShow > 0) {
                                setTimeout(function () {
                                    modal.show();
                                    updateCount(popupId);
                                }, secondsToShow);
                            } else {
                                modal.show();
                                updateCount(popupId);
                            }
                            Storage.removeItem(popupName)
                            var popupData = {
                                id: popupId,
                                time: now,
                            };
                            Storage.setItem(popupName, JSON.stringify(popupData));
                        }
                    }
                }
            }
        }
        closeBtn.click(function () {
            modal.hide();
        });
        $(window).on('click', function (e) {
            if ($(e.target).is('#pageModal1') || $(e.target).is('#pageModal2')) {
                modal.hide();
            }
        });
    }
    function updateCount(modalID) {
        var ajax_url = '/ajax/popupcount';
        $.ajax({
            method: "POST",
            url: ajax_url,
            data: {id: modalID},
            beforeSend: function () {
            }
        }).done(function (response) {
            //console.log(response);
        });
    }
    
    function updateLinksClick(modalID) {
        var ajax_url = '/ajax/popuplinksclick';
        $.ajax({
            method: "POST",
            url: ajax_url,
            data: {id: modalID},
            beforeSend: function () {
            }
        }).done(function (response) {
        });
    }
});