;(function () {
	'use strict';

	document.addEventListener("DOMContentLoaded", function(event) {
	
		var hideStickyCTS = function () {
			var footer = document.querySelector('.has-sticky-cta');
			if (typeof footer != 'undefined' && footer != null) {
				footer.classList.remove('has-sticky-cta');
			}
		}

		// register event listener to close button
		var closeButton = document.getElementById('close-sticky-cta');
		if (typeof closeButton !== 'undefined' && closeButton !== null) {
			closeButton.addEventListener('click', function (e) {
				e.preventDefault();
				Cookies.set('sticky-cta-hidden', '1');
				hideStickyCTS();
			});
		}
		
		// hide sticky cta if cookie is set
		if (Cookies.get('sticky-cta-hidden') == '1') {
			hideStickyCTS();
		}
		
	});
		
}());