'use strict';

(function () {
	/* touch detection */
	window.addEventListener('touchstart', function touched() {
		document.body.classList.add('touch');
		window.removeEventListener('touchstart', touched, false);
	}, false);
	
	/* lazy loading and button controls */
	var gallery = document.querySelector('.hero .gallery');
	if (gallery !== null) {
		var slides = gallery.querySelectorAll('li');
		var instructions = document.querySelector('.hero .gallery-instructions');
	
		Array.prototype.forEach.call(slides, function (slide) {
			var link = slide.querySelector('a'); 
			if (link !== null) {
				link.setAttribute('tabindex', '-1');
			}
		});
	
		var observerSettings = {
			root: gallery,
			rootMargin: '-10px'
		};
	
		if ('IntersectionObserver' in window) {
			var scrollIt = function scrollIt(slideToShow) {
				var scrollPos = Array.prototype.indexOf.call(slides, slideToShow) * (gallery.scrollWidth / slides.length);
				gallery.scrollLeft = scrollPos;
			};
	
			var showSlide = function showSlide(dir, slides) {
				var visible = document.querySelectorAll('.hero .gallery .visible');
				var i = dir === 'previous' ? 0 : 1;
	
				if (visible.length > 1) {
					scrollIt(visible[i]);
				} else {
					var newSlide = i === 0 ? visible[0].previousElementSibling : visible[0].nextElementSibling;
					if (newSlide) {
						scrollIt(newSlide);
					}
				}
			};
	
			var callback = function callback(slides, observer) {
				Array.prototype.forEach.call(slides, function (entry) {
					entry.target.classList.remove('visible');
					var link = entry.target.querySelector('a');
					if (link !== null) {
						link.setAttribute('tabindex', '-1');
					}
					if (!entry.intersectionRatio > 0) {
						return;
					}
					var img = entry.target.querySelector('img');
					entry.target.classList.add('visible');
					if (link !== null) {
						link.removeAttribute('tabindex', '-1');
					}
				});
			};
	
			var observer = new IntersectionObserver(callback, observerSettings);
			Array.prototype.forEach.call(slides, function (t) {
				return observer.observe(t);
			});
	
			var controls = document.querySelector('.hero .gallery-controls');
			controls.style.display = "block";
			document.body.classList.add('using-gallery-controls');
	
			controls.addEventListener('click', function (e) {
				showSlide(e.target.closest('button').getAttribute('data-direction'), slides);
			});
			
			// add fallback for scroll snap (Chrome)
			if (
				!("scroll-snap-type" in document.body.style) 
				&& !("-ms-scroll-snap-type" in document.body.style)
				&& !("-webkit-scroll-snap-type" in document.body.style)
			) {
				gallery.addEventListener('keydown', function (e) {
					if (e.keyCode === 37) { // left arrow
						e.preventDefault();
						showSlide('previous', slides);
					} else if (e.keyCode === 39) { // right arrow
						e.preventDefault();
						showSlide('next', slides);
					}
				});
			}
			
		}
	}
	$(window).on('scroll', function() {
    var scroll = $(window).scrollTop();
    var height = $(".hero").height() - 64;

    if (scroll >= height) {
			$(".sub-anchor-nav").addClass("is-sticky");
    } else {
			$(".sub-anchor-nav").removeClass("is-sticky");
    }
	});

	$(".sub-anchor-nav a[href*=\\#]:not([href=\\#])").on('click', function(){
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') || location.hostname == this.hostname){
			var target = $(this.hash),
			headerHeight = $("header").height() + 55;               
			if (target.length){
				$('html,body').animate({
					scrollTop: target.offset().top - headerHeight
				}, 500);
				return false;
			} 
		}
});
})();