$(document).ready(function () {
    $("#comments-filter-form .tag-filter input[type=checkbox]").on(
        "change",
        function () {
            if ($(this).hasClass("all")) {
                $(
                    "#comments-filter-form .tag-filter input[type=checkbox]"
                ).each(function (e) {
                    $(this).prop("checked", false);
                });
            }
            $("#comments-filter-form").submit();
        }
    );
});
