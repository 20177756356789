;(function () {
	'use strict';

	document.addEventListener("DOMContentLoaded", function(event) {
	
		var elements = document.querySelectorAll('button[role="switch"]');
		if (elements.length) {
			Array.prototype.forEach.call(elements, function (element) {
				element.addEventListener('click', function (e) {
					var checked = this.getAttribute('aria-checked') === 'true';
					if (checked) {
						this.setAttribute('aria-checked', false);
					} else {
						this.setAttribute('aria-checked', true);
					}
				});
			});
		}
		
	});
		
}());