;(function () {
	'use strict';
	
	var offsets = ['2%', '10%', '5%', '0%', '7%', '12%'];

	// Get all infographic lists
	var infographics = document.querySelectorAll('ul.infographic');

	Array.prototype.forEach.call(infographics, function (infographic) {
		
		infographic.classList.add('js-infographic');
		
		// get individual infographic items
		var items = infographic.children;
		Array.prototype.forEach.call(items, function (item, index) {
			
			// set left margin to offset
			item.style.marginLeft = offsets[index];
			
			// check for header and content wrappers
			var headerWrapper = item.querySelector('.infographic-icon');
			var contentWrapper = item.querySelector('.infographic-content');
			if (typeof(headerWrapper) != 'undefined' && headerWrapper != null && typeof(contentWrapper) != 'undefined' && contentWrapper != null) {
				
			    // generate id for content wrapper
				var contentWrapperID = Math.random().toString(36).substr(2, 9);
			    contentWrapper.setAttribute('id', contentWrapperID);
			    contentWrapper.hidden = true;
			    
			    // add button to header
			    headerWrapper.innerHTML = '<button aria-expanded="false" aria-controls="'+ contentWrapperID +'">' + headerWrapper.innerHTML + '</button>';
			    
				// Assign the button
				var btn = headerWrapper.querySelector('button');
				btn.onclick = function () {
					// Cast the state as a boolean
					var expanded = btn.getAttribute('aria-expanded') === 'true' || false;

					// Switch the state
					btn.setAttribute('aria-expanded', !expanded);
					// Switch the content's visibility
					contentWrapper.hidden = expanded;
				};
			}
		});
	});
}());
