;(function () {
	'use strict';
	
	// Get all snapshot hints and make them visible
	var snapshotHints = document.querySelectorAll('.snapshot-hint');
	Array.prototype.forEach.call(snapshotHints, function (snapshotHint) {
		snapshotHint.style.display = 'block';
	});

	// Get all snapshot lists
	var snapshotLists = document.querySelectorAll('ul.snapshots');

	Array.prototype.forEach.call(snapshotLists, function (snapshotList) {
		
		// get individual snapshot items
		var items = snapshotList.children;
		Array.prototype.forEach.call(items, function (item, index) {
			
			// check for header and content wrappers
			var headerWrapper = item.querySelector('.snapshot-title');
			var contentWrapper = item.querySelector('.snapshot-description');
			if (typeof(headerWrapper) != 'undefined' && headerWrapper != null && typeof(contentWrapper) != 'undefined' && contentWrapper != null) {
				
			    // add js class to list item
			    item.classList.add('js-snapshot');

			    // generate id for content wrapper
				var contentWrapperID = Math.random().toString(36).substr(2, 9);
			    contentWrapper.setAttribute('id', contentWrapperID);
			    contentWrapper.hidden = true;
			    
			    // add button to header
			    headerWrapper.innerHTML = '<button aria-expanded="false" aria-controls="'+ contentWrapperID +'" class="ac-focus-outline-default">' + headerWrapper.innerHTML + '<svg class="plus-minus ac-color-default" aria-hidden="true" focusable="false" viewBox="0 0 10 10" width="16" height="16"><rect class="vert" height="8" width="2" y="1" x="4"/><rect height="2" width="8" y="4" x="1"/></svg></button>';
			    
				// Assign the button
				var btn = headerWrapper.querySelector('button');
				btn.onclick = function () {
					// Cast the state as a boolean
					var expanded = btn.getAttribute('aria-expanded') === 'true' || false;

					// Switch the state
					btn.setAttribute('aria-expanded', !expanded);
					// Switch the content's visibility
					contentWrapper.hidden = expanded;
				};
			}
		});
	});
}());
