;(function () {
	'use strict';
	
	document.addEventListener("DOMContentLoaded", function(event) {
		var filterForm = document.querySelector('.ListedPageFilter');
		if (typeof(filterForm) != 'undefined' && filterForm != null) {
			// add js class to form
			filterForm.classList.add('js-filter');
			// get dropdowns
			var dropdowns = filterForm.querySelectorAll('select');
			Array.prototype.forEach.call(dropdowns, function (dropdown) {
				dropdown.onchange = function () {
					filterForm.submit();
				};
			});
		}
	});
	
}());
