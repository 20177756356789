;(function () {
	'use strict';

	// Get all the <h2> headings
	var headings = document.querySelectorAll('.accordion-title');

	Array.prototype.forEach.call(headings, function (heading) {
		
		// Function to create a node list 
	    // of the content between this heading and the next
		var getContent = function(elem) {
			var elems = [];
			while (elem.nextElementSibling && !elem.nextElementSibling.classList.contains('accordion-title')) {
				elems.push(elem.nextElementSibling)
				elem = elem.nextElementSibling
			}
					
			// Delete the old versions of the content nodes
			Array.prototype.forEach.call(elems, function (node) {
				node.parentNode.removeChild(node);
			})

			return elems;
		}
				
		// Assign the contents to be expanded/collapsed (array)
	    var content = getContent(heading);
	    
	    // Create a wrapper element for `contents` and hide it
	    var contentWrapper = document.createElement('div');
	    contentWrapper.classList.add('accordion-content');
	    contentWrapper.hidden = true
	    
	    // generate id for content wrapper
		var contentWrapperID = Math.random().toString(36).substr(2, 9);
	    contentWrapper.setAttribute('id', contentWrapperID);
	    
	    // Add each element of `content` to `contentWrapper`
	    Array.prototype.forEach.call(content, function (node) {
	    	contentWrapper.appendChild(node);
	    });
	    
	    // Add the wrapped content back into the DOM after the heading
	    heading.parentNode.insertBefore(contentWrapper, heading.nextElementSibling);
	    
		// Give each <h2> a toggle button child with the SVG plus/minus icon
		heading.innerHTML = '<button aria-expanded="false" aria-controls="'+ contentWrapperID +'"><p class="accor-txt">' + heading.innerHTML + '</p><svg class="plus-minus" aria-hidden="true" focusable="false" viewBox="0 0 10 10" width="16" height="16"><rect class="vert" height="8" width="2" y="1" x="4"/><rect height="2" width="8" y="4" x="1"/></svg></button>';
	    
	    // create wrapper for whole accordion item
		var accordionWrapper = document.createElement('div');
		accordionWrapper.classList.add('js-accordion');
		
		// add wrapper before heading
		heading.parentNode.insertBefore(accordionWrapper, heading);

		// add heading and content to wrapper
		accordionWrapper.appendChild(heading);
		accordionWrapper.appendChild(contentWrapper);
		
		// Assign the button
		var btn = heading.querySelector('button');

		btn.onclick = function () {
			// Cast the state as a boolean
			var expanded = btn.getAttribute('aria-expanded') === 'true' || false;

			// Switch the state
			btn.setAttribute('aria-expanded', !expanded);
			// Switch the content's visibility
			contentWrapper.hidden = expanded;
			
			// set url history if heading has an id
			if (heading.getAttribute('id') !== null && heading.getAttribute('id') !== "") {
				var headingID = heading.getAttribute('id');
				if(history.replaceState) {
					var url = window.location.pathname;
					if (!expanded) {
						url += '#' + headingID;
					}
					window.history.replaceState(undefined, undefined, url);
				}
				else {
					if (!expanded) {
						window.location.hash = '#' + headingID;
					} else {
						window.location.hash = '';
					}
				}
			}
		};
	});
	
	// jump to direct linked item
	var hash = window.location.hash;
	if (hash) {
            var _div = $("div" + hash);
            if(_div.length){
                $('html, body').animate({
                    scrollTop: _div.offset().top + 110
                }, 1000);
                setTimeout(function() {
                        hash = hash.substr(1); // remove #
                        if (typeof document.getElementById(hash) !== 'undefined' && document.getElementById(hash) !== null) {
                                var heading = document.getElementById(hash);
                                var button = heading.querySelector('button');
                                button.click();
                                button.focus();
                        }
                }, 1);
            }
        }
    
    (function(){
        var originalAddClassMethod = $.fn.addClass;
        var originalRemoveClassMethod = $.fn.removeClass;
        $.fn.addClass = function(){
            var result = originalAddClassMethod.apply( this, arguments );
            $(this).trigger('classChanged');
            return result;
        }
        $.fn.removeClass = function(){
            var result = originalRemoveClassMethod.apply( this, arguments );
            $(this).trigger('classChanged');
            return result;
        }
    })();
    var _parent = false;
    $('form.userform div.field.field.composite').each(function(){        
        $(this).bind('classChanged', function(){
            if(!_parent){
                _parent = $(this).closest(".elements");
            }
            var _parent_org_height = _parent.height();
            var my_height = $(this).height();            
        	if($(this).hasClass('hide')){
        	}else{
              _parent.css('height', _parent_org_height + my_height);
        	}
        });  
    });
}());
