jQuery(function($){
    $('#PredictiveSearchForm_search_Search').attr('autocomplete', 'off');
    var timeoutID = null;
    function solrsearchajax(str) {
        if(str.length >= 3){
            var form = $('form#PredictiveSearchForm_search');
            var url = form.attr('action');

            $.ajax({
               type: "GET",
               url: url,
               data: form.serialize(),
               success: function(data)
               {
                   $('#solr-results').html(data);
                   $('div.all-results a').on('click', function(e){
                        e.preventDefault();
                       $('form#PredictiveSearchForm_search').submit();
                    });
               }
             });
            $('#solr-results').addClass('show');
        }else{
            $('#solr-results').removeClass('show').html('');
        }
    }

    $('#PredictiveSearchForm_search_Search').keyup(function(e) {
        clearTimeout(timeoutID);
        timeoutID = setTimeout(function() {solrsearchajax(e.target.value), 500});
    });

});

$( ".search-button" ).click(function() {
  $( ".search-container" ).fadeToggle("fast");
  $(this).find(".fa-search").toggle();
  $(this).find(".icon-menu-close").toggle();
  $( "#nav #nav-main-list li span" ).fadeToggle("fast");
  $(".page-header").toggleClass("search-active");
  $('#PredictiveSearchForm_search_Search_Holder input[type="text"]').focus();
});

$('#PredictiveSearchForm_search_action_searchresults').attr('name', 'do');
