if (typeof(Storage) !== "undefined" && localStorage.getItem("fontsLoaded") == true) {
	document.documentElement.className += " fonts-loaded";
}

var fontA = new FontFaceObserver("franklin-gothic-urw");

Promise.all([fontA.load()]).then(function () {
    document.documentElement.className += " fonts-loaded";
    if (typeof(Storage) !== "undefined") {
    	sessionStorage.setItem("fontsLoaded", true);
    }
});
