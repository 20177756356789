;(function () {
	'use strict';
	
	// init main navigation
	document.addEventListener("DOMContentLoaded", function(event) {
		var menu = document.getElementById('nav');
		if (typeof(menu) != 'undefined' && menu != null) {
			
			var nav = new MainNavigation(menu, 1008);
	
			// handle skip link
			var skipLink = document.getElementById('skip-to-nav');
			skipLink.addEventListener('click', function (e) {
				nav.handleSkipLink(e);
			});
			skipLink.addEventListener('keydown', function (e) {
				if (e.keyCode === 13 || e.keyCode === 32) { // enter or space
					nav.handleSkipLink(e);
				}
			});
		}
	});

	// Constructor
	function MainNavigation (nav, desktopNavMinWidth) {
		
		var self = this;

		// Save a reference to the element
		this.menu = nav.querySelector('.ym-hlist > ul');
		this.menuId = this.menu.id;
		
		// get menu breakpoint width
		if (typeof(desktopNavMinWidth) === 'undefined' || desktopNavMinWidth == null) {
			this.desktopNavMinWidth = 1008;
		} else {
			this.desktopNavMinWidth = desktopNavMinWidth;
		}
		this.isDesktopView = false;
		
		// Get the button
		this.button = document.getElementById('nav-open');
		// If the menu button doesn't exist exit with an error
		if (!this.button) {
			throw new Error('Element `#nav-open` not found.');
		}

		// Add (initial) button semantics
		this.button.setAttribute('aria-haspopup', true);
		this.button.setAttribute('aria-expanded', false);
		this.button.setAttribute('role', 'button');
		this.button.setAttribute('aria-controls', this.menuId);

		// get close button if available
		var closeLink = document.getElementById('nav-close');
		if (typeof(closeLink) != 'undefined' && closeLink != null) {
			this.buttonClose = closeLink;
			this.buttonClose.setAttribute('role', 'button');
			this.buttonClose.setAttribute('aria-controls', this.menuId);
			
			// handle click events
			this.buttonClose.addEventListener('click', function (e) {
				e.preventDefault();
				this.close();
			}.bind(this))
			// handle key events
			this.buttonClose.addEventListener('keydown', function (e) {
				if (e.keyCode === 13 || e.keyCode === 32 || e.keyCode === 27) { 
					// enter or space or escape
					e.preventDefault();
					this.close();
					
				} else if (e.keyCode === 38 || (e.shiftKey && e.keyCode === 9)) { 
					// arrow up or shift+tab
					e.preventDefault();
					var links = this.menu.querySelectorAll('#nav-main-list > li > a');
					if (links.length > 0) {
						var last = links[links.length - 1];
						last.focus();
					}
					
				} else if (e.keyCode === 40 || e.keyCode === 9) { 
					// arrow down or tab
					e.preventDefault();
					
				}
			}.bind(this))
		}

		window.addEventListener('resize', throttle(function() {
			var width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
			if (width >= self.desktopNavMinWidth) {
				
				// desktop view
				self.isDesktopView = true;
				
				// hide the nav button
				self.button.hidden = true;
				
				// show menu
				self.menu.hidden = false;
				
				// focus nav if menu is open
				if (self.button.getAttribute('aria-expanded') !== 'true') {
					self.button.focus();
				}
				
			} else {
				
				// mobile view
				self.isDesktopView = false;
				
				// show the nav button
				self.button.hidden = false;

				// handle nav hash in url
				if (window.location.hash && window.location.hash == "#nav") {
					self.open();
					self.focusOpenMenuButton();
				} else {
					if (self.button.getAttribute('aria-expanded') !== 'true') {
						// Hide menu initially
						self.menu.hidden = true;
					}
				}

			}
		}, 200));
		window.dispatchEvent(new Event('resize'));
		
		// Get the all top level links within the menu
		this.menuLinks = this.menu.querySelectorAll('a');
		if (this.menuLinks.length < 1) {
			throw new Error('The #' + this.menuId + ' menu has no menu items');
		}

		// Handle key presses for menuItem
		Array.prototype.forEach.call(this.menuLinks, function (link, index) {
			
			// set initial state for child menues
			if (link.classList.contains('children')) {
				
				link.setAttribute('aria-haspopup', true);
				link.setAttribute('aria-expanded', false);
				link.setAttribute('role', 'button');
				link.setAttribute('aria-controls', link.parentNode.querySelector('ul').id);

				if (link.parentNode.classList.contains('open')) {
					this.openChildren(link);
				}
			}
			
			// add keyboard event
			link.addEventListener('keydown', function (e) {
				
				if (e.target.classList.contains('children')) { // handle children opening links
					
					if (this.isDesktopView) { // desktop view
						
						if (e.keyCode === 13 || e.keyCode === 32) { 
							// enter or space
							e.preventDefault();
							this.toggleChildren(e.target);
							
						} else if (e.keyCode === 37 || (e.shiftKey && e.keyCode === 9)) { 
							// left arrow or shift+tab
							if (e.target.getAttribute('aria-expanded') === 'true') {
								this.closeChildren(e.target);
							}
							focusNext(e, false, false);
							
						} else if (e.keyCode === 39) { 
							// right arrow
							if (e.target.getAttribute('aria-expanded') === 'true') {
								this.closeChildren(e.target);
							}
							focusNext(e, true, false);
							
						} else if (e.keyCode === 9) { 
							// or tab
							if (e.target.getAttribute('aria-expanded') === 'true') {
								e.preventDefault();
								e.target.parentNode.querySelector('li ul a').focus();
							} else {
								focusNext(e, true, false);
							}
							
						} else if (e.keyCode === 40) { 
							// down arrow
							if (e.target.getAttribute('aria-expanded') !== 'true') {
								e.preventDefault();
								this.openChildren(e.target);
							} else {
								e.preventDefault();
								e.target.parentNode.querySelector('li ul a').focus();
							}
							
						} else if (e.keyCode === 38) { 
							// up arrow
							e.preventDefault();
							this.closeChildren(e.target);
							
						} else if (e.keyCode === 27) { 
							// escape
							e.preventDefault();
							this.closeChildren(e.target);
						}

					} else { // mobile view
						
						if (e.keyCode === 13 || e.keyCode === 32) { 
							// enter or space
							e.preventDefault();
							this.toggleChildren(e.target);
							
						} else if (e.keyCode === 38 || (e.shiftKey && e.keyCode === 9)) { 
							// arrow up or shift+tab
							focusNext(e, false, false);
							
						} else if (e.keyCode === 40 || e.keyCode === 9) { 
							// arrow down or tab
							if (e.target.getAttribute('aria-expanded') === 'true') {
								e.preventDefault();
								e.target.parentNode.querySelector('li ul a').focus();
							} else {
								focusNext(e, true, false);
							}
							
						} else if (e.keyCode === 27) { 
							// escape
							e.preventDefault();
							if (e.target.getAttribute('aria-expanded') === 'true') {
								this.closeChildren(e.target);
							} else {
								this.close();
							}
						}
					}
				
				} else { // handle menu item links
					
					if (this.isDesktopView) { // desktop view
						
						if (e.keyCode === 37 || (e.shiftKey && e.keyCode === 9)) { 
							// left arrow or shift+tab
							focusNext(e, false, false);
						
						} else if (e.keyCode === 38) { 
							// up arrow
							if (
								(typeof e.target.parentNode.previousElementSibling === "undefined" || e.target.parentNode.previousElementSibling == null)
								&& (e.target.parentNode.parentNode.parentNode.classList.contains('children'))
							) {
								// first item in sub menu
								e.preventDefault();
								e.target.parentNode.parentNode.parentNode.querySelector('li.children a.children').focus();
							} else {
								focusNext(e, false, false);
							}
						
						} else if (e.keyCode === 40 || e.keyCode === 39 || e.keyCode === 9) { 
							// down arrow or right arrow or tab
							if (
								(typeof e.target.parentNode.nextElementSibling === "undefined" || e.target.parentNode.nextElementSibling == null)
								&& (e.target.parentNode.parentNode.parentNode.classList.contains('children'))
							) {
								// last item in sub menu
								focusNext(e, true, true);
							} else {
								focusNext(e, true, false);
							}
							
						} else if (e.keyCode === 27) { 
							// escape
							e.preventDefault();
							if (e.target.parentNode.parentNode.parentNode.classList.contains('children')) {
								this.closeChildren(e.target.parentNode.parentNode.parentNode.querySelector('a.children'));
							}
						}
						
					} else { // mobile view
						
						if (e.shiftKey && e.keyCode === 9) { 
							// shift+tab
							focusNext(e, false, false);
						
						} else if (e.keyCode === 38) { 
							// up arrow
							if (
								(typeof e.target.parentNode.previousElementSibling === "undefined" || e.target.parentNode.previousElementSibling == null)
								&& (e.target.parentNode.parentNode.parentNode.classList.contains('children'))
							) {
								// first item in sub menu
								e.preventDefault();
								e.target.parentNode.parentNode.parentNode.querySelector('li.children a.children').focus();
							} else {
								focusNext(e, false, false);
							}
						
						} else if (e.keyCode === 40 || e.keyCode === 9) { 
							// down arrow or tab
							if (
								(typeof e.target.parentNode.nextElementSibling === "undefined" || e.target.parentNode.nextElementSibling == null)
								&& (e.target.parentNode.parentNode.parentNode.classList.contains('children'))
							) {
								// last item in sub menu
								focusNext(e, true, true);
							} else {
								focusNext(e, true, false);
							}
							
						} else if (e.keyCode === 27) { 
							// escape
							e.preventDefault();
							if (e.target.parentNode.parentNode.parentNode.classList.contains('children')) {
								this.closeChildren(e.target.parentNode.parentNode.parentNode.querySelector('a.children'));
							} else {
								this.close();
							}
						}
					}
				}
			}.bind(this))
			link.addEventListener('click', function (e) {
				// check for children links
				if (e.target.classList.contains('children')) {
					e.preventDefault();
					this.toggleChildren(e.target);
				}
			}.bind(this))
		}.bind(this))
		
		var focusNext = function (event, down, preventExit) {
			var link = event.target;
			var goingDown = down;
			preventExit = typeof preventExit === "undefined" ? true : preventExit;
			
			// helper function for getting next legitimate element
			function getNextElement(link) {
				if (goingDown) {
					
					if ( // check if there is a direct sibling link (.children)
						typeof link.nextElementSibling !== "undefined" 
						&& link.nextElementSibling != null
						&& link.nextElementSibling.tagName == 'A'
						&& !isElementHidden(link.nextElementSibling)
					) { 
						return link.nextElementSibling;
					} else if ( // check if there is a visible next nav element
						typeof link.parentNode.nextElementSibling !== "undefined" 
						&& link.parentNode.nextElementSibling != null
						&& !isElementHidden(link.parentNode.nextElementSibling)
					) { 
						// next element exists and is visible
						return link.parentNode.nextElementSibling.querySelector('a');
					} else {
						return link;
					}
					
				} else {

					if ( // check if there is a direct sibling link (.children)
						typeof link.previousElementSibling !== "undefined" 
						&& link.previousElementSibling != null
						&& link.previousElementSibling.tagName == 'A'
						&& !isElementHidden(link.previousElementSibling)
					) { 
						return link.previousElementSibling;
					} else if ( // check if there is a visible next nav element
						typeof link.parentNode.previousElementSibling !== "undefined" 
						&& link.parentNode.previousElementSibling != null
						&& !isElementHidden(link.parentNode.previousElementSibling)
					) { 
						// check if there is a child link and focus there is yes
						if (link.parentNode.previousElementSibling.querySelector('a.children')) {
							return link.parentNode.previousElementSibling.querySelector('a.children');
						} else {
							return link.parentNode.previousElementSibling.querySelector('a');
						}
					} else {
						return link;
					}
				}
			}
			
			function isElementHidden(element) {
			    return (element.offsetParent === null);
			}

			// make move
			var nextItem = getNextElement(link);
			if (preventExit || nextItem !== link) {
				event.preventDefault();
				nextItem.focus();
			}

		}.bind(this)

		// Handle button click
		this.button.addEventListener('click', function (e) {
			e.preventDefault();
			this.toggle();
		}.bind(this))

		// Also toggle on key interactions
		this.button.addEventListener('keydown', function (e) {
			
			if (e.keyCode === 13 || e.keyCode === 32) { 
				// enter or space
				e.preventDefault();
				this.toggle();
				
			} else if (e.keyCode === 40) { 
				// down arrow
				if (this.button.getAttribute('aria-expanded') !== 'true') {
					e.preventDefault();
					this.open();
				} else {
					this.menu.querySelector('a').focus()
				}
				
			} else if ((e.shiftKey && e.keyCode === 9)) { 
				// shift+tab
				this.close(false);
				
			} else if (e.keyCode === 9) { 
				// or tab
				if (this.button.getAttribute('aria-expanded') === 'true') {
					e.preventDefault();
					this.menu.querySelector('a').focus()
				}
				
			} else if (e.keyCode === 38) { 
				// up arrow
				this.close();
				
			} else if (e.keyCode === 27) { 
				// escape
				this.close();
			}
		}.bind(this))
		
		// register outside click
		this.outsideClick = function (e) {
			if (this.button.getAttribute('aria-expanded') === 'true' && !this.menu.contains(e.target) && !this.button.contains(e.target)) {
				this.close();
			}
			if (this.isDesktopView && this.menu.querySelector('a.children[aria-expanded=true]') != null && !this.menu.contains(e.target)) {
				this.closeAllSubmenus();
			}
		}
		document.addEventListener('click', this.outsideClick.bind(this));

		// initiate listeners object for public events
		this._listeners = {}
	}
	
	// Open methods
	MainNavigation.prototype.open = function () {
		// open nav
		this.button.setAttribute('aria-expanded', true);
		this.menu.hidden = false;
		// add jsnav class for css
		var html = document.getElementsByTagName( 'html' )[0];
		html.classList.add('jsnav');
		// scroll to top
		window.scrollTo(0,0);
		// fire open event
		this._fire('open');
		return this;
	}
	MainNavigation.prototype.openChildren = function (link) {
		// close all other sub menus
		this.closeAllSubmenus();
		// open nav
		link.setAttribute('aria-expanded', true);
		link.parentNode.querySelector('ul').hidden = false;
		// add class for css
		link.parentNode.classList.add('open');
		// fire open event
		this._fire('openchildren');
		return this;
	}

	// Close methods
	MainNavigation.prototype.close = function (setFocus) {
		setFocus = typeof setFocus === "undefined" ? true : setFocus;
		// close nav
		this.button.setAttribute('aria-expanded', false);
		this.menu.hidden = true;
		// remove class for css
		var html = document.getElementsByTagName( 'html' )[0];
		html.classList.remove('jsnav');
		// set focus
		if (setFocus) {
			this.button.focus();
		}
		// fix hash
		if(typeof window.location.hash !== "undefined" && window.location.hash == "#nav"){
			var href = window.location.href.split('#')[0];
			if(history.pushState) {
			    history.pushState(null, null, href);
			} else {
			    window.location = href;
			}
		}
		// fire close event
		this._fire('close');
		return this;
	}
	MainNavigation.prototype.closeChildren = function (link, setFocus) {
		setFocus = typeof setFocus === "undefined" ? true : setFocus;
		// close nav
		link.setAttribute('aria-expanded', false);
		link.parentNode.querySelector('ul').hidden = true;
		// remove class for css
		link.parentNode.classList.remove('open');
		// set focus
		if (setFocus) {
			link.focus();
		}
		// fire close event
		this._fire('closechildren');
		return this;
	}
	MainNavigation.prototype.closeAllSubmenus = function () {
		var self = this;
		var subMenuButtons = this.menu.querySelectorAll('a.children');
		if (subMenuButtons.length > 0) {
			Array.prototype.forEach.call(subMenuButtons, function (link) {
				self.closeChildren(link, false);
			});
		}
		this._fire('closeallsubmenus');
		return this;
	}

	// Toggle methods
	MainNavigation.prototype.toggle = function () {
		var expanded = this.button.getAttribute('aria-expanded') === 'true';
		return expanded ? this.close() : this.open();
	}
	MainNavigation.prototype.toggleChildren = function (link) {
		var expanded = link.getAttribute('aria-expanded') === 'true';
		return expanded ? this.closeChildren(link) : this.openChildren(link);
	}
	
	// handle skip link
	MainNavigation.prototype.handleSkipLink = function (event) {
		if (this.isDesktopView) {
			event.preventDefault();
			document.getElementById('nav').focus();
		} else {
			event.preventDefault();
			this.open();
			this.focusOpenMenuButton();
		}
		return this;
	}

	// focus menu button
	MainNavigation.prototype.focusOpenMenuButton = function () {
		this.button.focus();
		return this;
	}

	MainNavigation.prototype._fire = function (type, data) {
		if (typeof this._listeners === 'undefined') {
			this._listeners = [];
		}
		var listeners = this._listeners[type] || [];

		listeners.forEach(function (listener) {
			listener(data);
		})
	}

	MainNavigation.prototype.on = function (type, handler) {
		if (typeof this._listeners[type] === 'undefined') {
			this._listeners[type] = [];
		}

		this._listeners[type].push(handler);

		return this;
	}

	MainNavigation.prototype.off = function (type, handler) {
		var index = this._listeners[type].indexOf(handler);

		if (index > -1) {
			this._listeners[type].splice(index, 1);
		}

		return this;
	}

	// Export MainNavigation
	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		module.exports = MainNavigation;
	} else if (typeof define === 'function' && define.amd) {
		define('MainNavigation', [], function () {
			return MainNavigation;
		})
	} else {
		// attach to window
		window.MainNavigation = MainNavigation;
	}
}());

$( "#nav .projects-parent" ).click(function() {
  $( "#children-projects" ).toggleClass("active");
});

$( "#nav a.children" ).click(function() {
  $(this).next("ul").toggleClass("active");
});