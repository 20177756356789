;(function () {
	'use strict';

	window.addEventListener('scroll', throttle(function() {
		var fromTop = window.pageYOffset | document.body.scrollTop;
		if (fromTop > 100) {
			if (!document.body.classList.contains('scrolled')) {
				document.body.classList.add('scrolled');
			}
		} else {
			document.body.classList.remove('scrolled');
		}
	}, 200));
	
	// correct position of anchor scroll
	document.addEventListener('click', function (event) {
		
		// check if clicked on a link
		if (event.target.nodeName != "A") return;
		
		var link = event.target;
		var hash = link.href.split("#")[1] || false;
		if (hash && !link.classList.contains('no-hash-scroll')) {
			if (link.href.split('#')[0] == window.location.href.split('#')[0]) {
				var target;
				var offset = 0;
				if (typeof document.getElementById(hash) !== 'undefined' && document.getElementById(hash) !== null) {
					target = document.getElementById(hash);
					offset = target.getBoundingClientRect().top + window.pageYOffset;
				} else if (typeof document.querySelector('a[name=' + hash + ']') !== 'undefined' && document.querySelector('a[name=' + hash + ']') !== null) {
					target = document.querySelector('a[name=' + hash + ']');
					offset = target.getBoundingClientRect().top + window.pageYOffset;
				}
				if (offset > 0) {
					// get header height
					var height = document.querySelector('.page-header').offsetHeight;
					// calculate position
					var position = offset - height - 32; // less header height and some padding
					// focus target
					target.focus();
					//scroll to element
					window.scrollTo({
					    top: position,
					    behavior: "smooth"
					});
					// update url hash 
					if(history.pushState) {
					    history.pushState(null, null, link.href);
					} else {
					    location.hash = hash;
					}
					// prevent default jump
					event.preventDefault();
					return false;
					
				}
			}
		}
	}, false);
	
	//do the same with urls with hash too (so on page load it will slide nicely)
	if (window.location.hash && window.location.hash != "#nav") { // nav skip link handled in nav script
		var hash = window.location.href.split("#")[1];
		var target;
		var offset = 0;
		if (typeof document.getElementById(hash) !== 'undefined' && document.getElementById(hash) !== null) {
			target = document.getElementById(hash);
			offset = Math.round(target.getBoundingClientRect().top + window.pageYOffset);
		} else if (typeof document.querySelector('a[name=' + hash + ']') !== 'undefined' && document.querySelector('a[name=' + hash + ']') !== null) {
			target = document.querySelector('a[name=' + hash + ']');
			offset = Math.round(target.getBoundingClientRect().top + window.pageYOffset);
		}
		if (offset > 0) {
			// get header height
			var height = document.querySelector('.page-header').offsetHeight;
			// calculate position
			var position = offset - height; // less header height
			// focus target
			target.focus();
			//scroll to element
			window.scrollTo({
			    top: position,
			    behavior: "smooth"
			});
			return false;
		}
	}
	
}());