;(function () {
	'use strict';
	
	// Get all infographic lists
	var timelines = document.querySelectorAll('ul.timeline');

	Array.prototype.forEach.call(timelines, function (timeline) {
		
		timeline.classList.add('js-timeline');
		
		// get individual infographic items
		var items = timeline.children;
		Array.prototype.forEach.call(items, function (item, index) {
			
			// check for header and content wrappers
			var headerWrapper = item.querySelector('.step-header');
			var contentWrapper = item.querySelector('.timeline-content');
			if (typeof(headerWrapper) != 'undefined' && headerWrapper != null && typeof(contentWrapper) != 'undefined' && contentWrapper != null) {
				
			    // generate id for content wrapper
				var contentWrapperID = Math.random().toString(36).substr(2, 9);
			    contentWrapper.setAttribute('id', contentWrapperID);
			    
			    // add button to header
			    headerWrapper.innerHTML = '<button aria-expanded="false" aria-controls="'+ contentWrapperID +'">' + headerWrapper.innerHTML + '</button>';
			    
				// Assign the button
				var btn = headerWrapper.querySelector('button');
				btn.onclick = function () {
					// Cast the state as a boolean
					var expanded = btn.getAttribute('aria-expanded') === 'true' || false;

					// Switch the state
					btn.setAttribute('aria-expanded', !expanded);
					// Switch the content's visibility
					contentWrapper.hidden = expanded;
				};
				
				// open active element
			    if (item.classList.contains('active')) {
			    	btn.setAttribute('aria-expanded', true);
			    } else {
			    	contentWrapper.hidden = true;
			    }

			}
		});
	});
}());
