$( ".read-more" ).click(function() {
    var readmore_button = $(this);
    var readmore_content = $( this ).parent().find('.read-more-content');
    readmore_content.slideToggle( "fast", function() {
    
        if(readmore_content.is(':visible')){
            readmore_button.html('Read less<svg class="icon icon-caret-up " aria-labelledby="caret-up-title-id-345" aria-label="CaretUp" role="img" width="16" height="16"><title id="caret-up-title-id-345">CaretUp</title><use xlink:href="/resources/themes/landcom/images/icons.svg#icon-caret-up"></use></svg>');
        }else{
            readmore_button.html('Read more<svg class="icon icon-caret-down " aria-labelledby="caret-down-title-id-209" aria-label="CaretDown" role="img" width="16" height="16"><title id="caret-down-title-id-209">CaretDown</title><use xlink:href="/resources/themes/landcom/images/icons.svg#icon-caret-down"></use></svg>');
        }
    });
  $(this).toggleClass("is-active");
});