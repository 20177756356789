$(document).ready(function() {   
    if($( ".drag-inner" ).length){
        $( ".drag-inner" ).sortable({
            connectWith: ".drag-zone",
            cancel: "notes",
            start: function( event, ui ) {
                $(ui.item).addClass("active-draggable");
                $('.drag-inner').not($(ui.item).parent()).addClass('stroke');
            },
            stop:function( event, ui ) {
                $(ui.item).removeClass("active-draggable");
                $('.drag-inner').removeClass('stroke');
                var dragContainer = $(event.target).parent();
                var dropped_list = dragContainer.find('.drag-zone .drag-item');
                if(dropped_list.length > 0){
                    var list = [];
                    dropped_list.each(function() {
                        var _name = $(this).attr('data-name');
                        list.push(_name);
                    });
                    list = list.join(', ');
                }
                var _input_field = dragContainer.find('input.dropped-list');
                _input_field.val(list);
            }
        }).disableSelection();
        }
    
    $('body').on('click tab', '.select-item.multi', function(){
        if( $(this).hasClass('active')){
            $(this).addClass('blur');
            $(this).removeClass('active');
        }else{
            $(this).addClass('active');
            $(this).removeClass('blur');
        }
        var list = [];
        $('.select-container .select-item.multi.active').each(function(){
            var _name = $(this).attr('data-name');
            list.push(_name);
        });
        list = list.join(', ');
        
        var _comment = $(this).parents('.select-container').find('textarea').val();
        if(_comment != ''){
            var list = list + "\r\nOther:\r\n" + _comment;
        }
        var _input_field = $(this).parents('.select-container').find('input.multipleimages-list');        
        
        _input_field.val(list);
    });
    
    $('body').on('click tab', '.select-item.single', function(){
        var parent_container = $(this).parents('.select-container');
        parent_container.find('.select-item.single').removeClass('active');        
        parent_container.find('textarea').val();        
        
        $(this).addClass('active');
        var _input_field = parent_container.find('input.multipleimagesradio-list');
        var value = $(this).attr('data-name');        
        _input_field.val(value);
    });
    
    $('input.othermulti').on('change', function(){
        var comment_field = $(this).parent().find('textarea');
        if($(this).is(":checked")){
            comment_field.removeClass('hide');
        }else{
            comment_field.val('');            
            comment_field.addClass('hide');
            var _input_field = $(this).parents('.select-container').find('input.multipleimages-list');
            var list = [];
            $('.select-container .select-item.active').each(function(){
                var _name = $(this).attr('data-name');
                list.push(_name);
            });
            var _current_val = list.join(', ');
            _input_field .val(_current_val);
        }
    });
    
    $('input.othersingle').on('change', function(){
        var parent_container = $(this).parents('.select-container');
        var comment_field = $(this).parent().find('textarea');
        var _input_field = parent_container.find('input.multipleimagesradio-list');
        if($(this).is(":checked")){
            comment_field.removeClass('hide');
            parent_container.find('.select-item.single').removeClass('active');
            _input_field .val('');
        }else{
            comment_field.val('');            
            comment_field.addClass('hide');
        }
    });
    $('.select-container textarea.multi').on('change keyup paste', function() {
        var parent_container = $(this).parents('.select-container');
        var _input_field = parent_container.find('input.multipleimages-list');
        var list = [];
        $('.select-container .select-item.multi.active').each(function(){
            var _name = $(this).attr('data-name');
            list.push(_name);
        });
        var _current_val = list.join(', ');
        var _comment = $(this).val();
        if(_comment != ''){
            var _new_val = _current_val + "\r\nOther:\r\n" + _comment;
            _input_field .val(_new_val);
        }
    });
    
    $('.select-container textarea.single').on('change keyup paste', function() {
        var parent_container = $(this).parents('.select-container');
        var _input_field = parent_container.find('input.multipleimagesradio-list');
        var _comment = $(this).val();
        var _new_val = "Other:\r\n" + _comment;
        _input_field .val(_new_val);
    });
    
    $('form.userform button.step-button-next, form.userform button.step-button-prev').on('click', function(){        
        var position_to_scroll = $(this).parents('form.userform').offset().top - 170;
        $('html,body').animate({
            scrollTop: position_to_scroll
        }, 500);
    });
    
    $('.satisfy-item input[type=radio]').on('change', function(e){
        var container = $(this).parents('.satisfy-container');
        var satisfy_list = [];
        container.find('input[type=radio]').each(function(e){
            var radio = $(this);
            if(radio.is(":checked")){                
                var itemId = radio.data('id');
                var result = radio.attr('name') + ': ' + $(this).val();
                satisfy_list[itemId] = result;                
            }
        });
        satisfy_list.shift();
        var results = satisfy_list.join("\r\n");
        container.find('.satisfy-results').val(results);
    });    
    
    $('input.other-satisfy-reason').on('change', function(){
        var comment_field = $(this).parent().find('textarea');
        if($(this).is(":checked")){
            comment_field.removeClass('hide');
        }else{
            comment_field.val('');            
            comment_field.addClass('hide');
        }
    });
    $('.satisfy-container textarea.other-reason').on('change keyup paste', function() {
        var container = $(this).parents('.satisfy-container');
        var satisfy_list = [];
        container.find('input[type=radio]').each(function(e){
            var radio = $(this);
            if(radio.is(":checked")){                
                var itemId = radio.data('id');
                var result = radio.attr('name') + ': ' + $(this).val();
                satisfy_list[itemId] = result;                
            }
        });
        satisfy_list.shift();
        var results = satisfy_list.join("\r\n");
        var comment = $(this).val();        
        if(comment != ''){
            var new_val = results + "\r\nOther:\r\n" + comment;
            container.find('.satisfy-results').val(new_val);
        }
    });
});
