;(function () {
	// 'use strict';

	$(window).bind("load", function() {
		var elements = document.querySelectorAll('.element.elementcolumnedlist .elements');
		Array.prototype.forEach.call(elements, function (elem) {
			if (typeof elem !== 'undefined' && elem !== null) {
				var msnry = new Masonry( elem, {
					itemSelector: '.element',
					columnWidth: '.element:first-child',
					percentPosition: true,
					gutter: 32,
					initLayout: false
				});
				setTimeout(function(){
					msnry.layout();
				}, 700);
			}
		});
	});
}());
